.card {
  width: 240px;
  font-family: inherit;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #ccc;
}
.card:hover {
  transition: all 100ms ease;
  transform: translate(0, -7px);
  cursor: pointer;
  box-shadow: 1px 5px 15px #0a164c;
}
.card .card-image {
  position: relative;
  display: flex;
  justify-content: center;
}
.card .card-image img {
  width: 100%;
  height: 300px;
}
.card .progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
}

.card .read-book {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0c184e57;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.card .read-book-button {
  border: 2px solid white;
  color: white;
  font-size: 20px;
  background: none;
  margin-bottom: 30px;
  padding: 5px 15px;
  animation: 350ms ease-out 0s 1 slideInFromBottom;
}

.card .read-book-button:hover {
  background-color: #0a164c;
  cursor: pointer;
  box-shadow: 1px 5px 15px #0a164c;
}

@keyframes slideInFromBottom {
  0% {
    margin-bottom: -100px;
  }
  100% {
    margin-bottom: 30px;
  }
}

.card .card-content {
  position: relative;
  padding: 10px;
  height: 163px;
}

.card .tags-container {
  width: 100%;
  display: flex;
  gap: 5px;
}

.card .tags {
  font-size: 12px;
  padding: 5px;
  background-color: rgb(199 199 199 / 40%);
  border-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 95%;
  width: fit-content;
}

.card .more-tags {
  font-size: 12px;
  padding: 5px;
  background-color: rgb(199 199 199 / 40%);
  border-radius: 4px;
  max-width: 95%;
  width: fit-content;
}

.card .card-content h3 {
  margin-top: 10px;
  font-size: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 46px;
}

.authors {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}
.card .single-author {
  font-size: 12px;
  padding: 4px;
  border-radius: 4px;
  color: white;
  background-color: #0a164c;
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.more-authors {
  font-size: 12px;
  padding: 4px;
  border-radius: 4px;
  color: white;
  background-color: #0a164c;
}

.card .pages-row-stack {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}
.card .pages-row-stack .page-svg {
  width: 25px;
  height: 22px;
}
.card .favorite-svg,
.card .add-favorite-svg {
  position: absolute;
  right: 10px;
  bottom: 14px;
}
.card .favorite-svg,
.card .add-favorite-svg {
  width: 24px;
  height: 24px;
}
