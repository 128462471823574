.terms-container {
  font-family: inherit;
  display: flex;
  justify-content: center;
}
.terms-wrapper {
  max-width: 1330px;
}
.terms-wrapper h2 {
  font-size: 28px;
  color: #272e6e;
}
