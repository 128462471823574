.btn-top {
  cursor: pointer;
  position: sticky;
  background: transparent;
  border: none;
  left: 10px;
  bottom: 10px;
  z-index: 1;
}
.back-top-svg {
  fill: #0b164c;
  width: 30px;
  height: 30px;
}
@media (max-width: 500px) {
  .btn-top {
    left: 0;
  }
}
@media (min-width: 1200px) {
  .back-top-svg {
    width: 40px;
    height: 40px;
  }
}
@media (min-width: 1900px) {
  .back-top-svg {
    width: 50px;
    height: 50px;
  }
}
