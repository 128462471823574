.register-wrapper {
  font-family: inherit;
}
.register-wrapper .register-container {
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  width: 400px;
  gap: 10px;
}
.register-wrapper .register-container h2 {
  font-size: 28px;
  position: relative;
}
.register-wrapper .error {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid red;
  color: red;
}
.register-wrapper .error-svg {
  width: 25px;
  height: 25px;
}
.register-wrapper .btn-login {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.successful {
  display: flex;
  align-items: center;
  height: 38px;
  padding: 5px 10px;
  color: #30403b;
}
.terms {
  font-size: 15px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 5px;
  position: relative;
}
.terms .checkbox {
  width: auto;
  height: auto;
}
.terms .checkbox .error-message {
  position: absolute;
  top: 20px;
}
.terms .checkbox-label {
  display: none;
}
.password-container {
  position: relative;
  width: 100%;
}
.eye-svg {
  position: absolute;
  right: 10px;
  top: 15px;
  cursor: pointer;
}

@media (max-width: 500px) {
  .register-wrapper .register-container {
    width: 100%;
  }
}
@media (max-width: 420px) {
  .terms {
    font-size: 13px;
  }
  .terms .checkbox input {
    width: 15px;
    height: 15px;
  }
  .terms .checkbox .error-message {
    top: 16px;
  }
}
