.home-banner {
  height: 300px;
  margin-bottom: 30px;
  border-radius: 0 0 5px 5px;
  overflow: hidden;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-button-prev,
.swiper-button-next {
  color: white !important;
}

.swiper .swiper-pagination-progressbar-fill {
  background-color: #3f78e0 !important;
}

.custom-slide {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: start;
  color: white;
}
.custom-slide::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(30, 34, 40, 0.4);
  display: block;
  top: 0;
  left: 0;
  z-index: 1;
}

.custom-slide .context {
  width: 585px;
  margin-left: 10%;
  z-index: 2;
}

.custom-slide .context h2 {
  margin-top: 0;
}

.custom-slide .context button {
  background: unset;
  border: 1px solid white;
  border-radius: 5px;
  padding: 5px 10px;
  color: white;
  z-index: 9999;
  cursor: pointer;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 900px) {
  .custom-slide .context {
    width: 77%;
    margin-left: 13%;
  }
  .custom-slide .context p {
    font-size: 15px;
  }
}
@media (max-width: 560px) {
  .home-banner {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    height: 250px;
    border-radius: 0;
  }
  .custom-slide .context {
    width: 74%;
    margin-left: 13%;
  }
  .custom-slide .context h2 {
    font-size: 20px;
  }
  .custom-slide .context p {
    font-size: 12px;
  }
}
