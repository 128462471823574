.footer-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #0b164c;
  color: white;
  margin-top: 50px;
}

.footer-container {
  padding: 50px 0;
  max-width: 1330px;
  width: 80%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5%;
}

.footer-section {
  min-width: 20%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.footer-section p {
  width: fit-content;
  border-bottom: 1px solid white;
}
.footer-wrapper img {
  width: 100px;
}

.footer-link {
  text-decoration: none;
  color: white;
  width: fit-content;
  border-bottom: 1px solid white;
}
.social-media a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  fill: white;
  border-radius: 50%;
}
.social-media a:visited {
  color: white;
}
.footer-bottom {
  padding: 10px 0;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #030c33;
}

.footer-bottom div {
  width: 80%;
  max-width: 1330px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-bottom span {
  font-size: 14px;
}

.social-media {
  display: flex;
  gap: 15px;
}

.social-media span {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  fill: white;
  border: 1px solid white;
  border-radius: 50%;
}

@media (max-width: 1000px) {
  .footer-container {
    grid-template-columns: 1fr 1fr;
    gap: 5%;
  }
  .map {
    height: 300px;
    grid-column: span 2;
  }
}
@media (max-width: 500px) {
  .footer-container {
    grid-template-columns: 1fr;
    gap: 5%;
  }
  .map {
    height: 277px;
    grid-column: auto;
    margin-bottom: 50px;
  }
}
@media (max-width: 380px) {
  .footer-bottom span {
    font-size: 13px;
  }
}
