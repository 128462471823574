.text-area-input-box textarea {
  resize: vertical;
  min-height: 50px;
  max-height: 300px;
  padding-top: 10px;
  width: -webkit-fill-available;
  width: -moz-available;
  outline: none;
  border: 1px solid #ccc;
  padding-left: 10px;
  font-size: 16px;
  font-family: inherit;
  -webkit-appearance: none;
  border-radius: 0;
}
.text-area-input-box textarea:focus {
  border-color: #3f78e0;
  box-shadow: 0px 0px 4px #3f78e0;
}
