.custom-file-upload-image {
  border: 1px solid #ccc;
  display: inline-block;
  background: rgb(0, 0, 0, 0.1);
  padding: 6px 12px;
  cursor: pointer;
  width: -webkit-fill-available;
  width: -moz-available;
  height: 200px;
  background-repeat: no-repeat !important;
  text-align: center;
  margin-bottom: 15px;
}
.cover-image {
  width: 100%;
}
.cover-image input {
  display: none;
}

.cover-text {
  margin-top: 100px;
  font-style: normal;
  font-weight: bold;
  font-family: inherit;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.custom-file-upload-image .svg-icon {
  margin-top: 70px;
  position: absolute;
  height: 15px;
  width: 15px;
  font-weight: bold;
}
