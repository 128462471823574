.manage-authors-container {
  margin: 20px;
  font-family: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0;
  padding-top: 20px;
}
.manage-authors-wrapper {
  max-width: 1330px;
  width: 100%;
  overflow-x: auto;
}
.manage-authors-header .title {
  display: flex;
  position: relative;
  gap: 5px;
}
.manage-authors-header .back-arrow {
  height: 28px;
  width: 28px;
  position: absolute;
  left: -40px;
  top: 6px;
  cursor: pointer;
}
.manage-authors-header {
  max-width: 1330px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.authors-table-container {
  margin-top: 30px;
  margin-bottom: 50px;
  width: 100%;
}
.authors-filter-wrapper {
  display: flex;
  gap: 10px;
}
.authors-filter-wrapper .btn-primary {
  height: 34px;
  width: 170px;
}
.manage-authors-header .filter-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2px;
  gap: 10px;
}
.manage-authors-header .input-box {
  width: 170px;
}
.manage-authors-header .input-box input {
  height: 30px;
}
.manage-authors-header .input-box .custom-placeholder {
  top: 6px;
}
.authors-table-container .edit-svg {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.authors-table-container .table-cell {
  height: 55px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 15px;
}

.authors-table-container .name {
  width: 45%;
  min-width: 200px;
}
.authors-table-container .birthday {
  width: 45%;
  min-width: 100px;
}
@media (max-width: 740px) {
  .manage-authors-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .authors-filter-wrapper {
    flex-direction: column;
  }
  .manage-authors-header .input-box,
  .authors-filter-wrapper .btn-primary {
    width: 270px;
  }
}
