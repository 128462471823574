.manage-users-container {
  margin: 20px;
  font-family: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0;
  padding-top: 20px;
}
.manage-users-wrapper {
  max-width: 1330px;
  width: 100%;
  overflow-x: auto;
}
.manage-users-header {
  max-width: 1330px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.manage-users-header .title {
  display: flex;
  position: relative;
  gap: 5px;
}
.manage-users-header .back-arrow {
  height: 28px;
  width: 28px;
  position: absolute;
  left: -40px;
  top: 6px;
  cursor: pointer;
}
.title h2 {
  color: #272e6e;
  margin: 0;
  font-size: 28px;
}
.user-filter-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2px;
  gap: 10px;
}
.user-filter-container .input-box,
.user-filter-container .select-box {
  width: 160px;
}

.user-filter-container .input-box input {
  height: 30px;
}
.user-filter-container .select__control {
  height: 34px;
  min-height: 10px;
}
.user-filter-wrapper .btn-primary {
  height: 34px;
  width: 160px;
}
.user-filter-container .select-box .custom-placeholder,
.user-filter-container .input-box .custom-placeholder {
  top: 6px;
}
.user-filter-container .select__placeholder,
.user-filter-container .select__single-value {
  margin-top: -4px;
}

.user-container {
  margin-top: 30px;
  margin-bottom: 50px;
  width: 100%;
}
.user-container .edit-svg {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.table-row {
  display: flex;
  border-bottom: 1px solid #ccc;
}
.header {
  border-bottom: 2px solid #ccc;
}
.user-container .table-cell {
  height: 55px;
  min-width: 70px;
  max-width: 250px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 15px;
}
.table-row td {
  font-weight: 500;
}
.name {
  width: 20%;
}
.email {
  width: 25%;
}
.ellipsis-div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.role {
  width: 10%;
}
.user-type {
  width: 20%;
  white-space: nowrap;
}
.status {
  width: 15%;
  justify-content: center !important;
}
.single-status {
  padding: 3px 10px;
  display: flex;
  justify-content: center;
  width: 70px;
  font-weight: 700;
}
.icons {
  width: 10%;
  min-width: 60px;
  display: flex;
  justify-content: flex-end !important;
  padding-right: 10px;
  gap: 10px;
}
@media (max-width: 1160px) {
  .manage-users-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}
@media (max-width: 760px) {
  .user-filter-container {
    flex-direction: column;
  }
  .user-filter-container .select-box,
  .user-filter-wrapper .btn-primary,
  .user-filter-container .input-box {
    width: 280px;
  }
}
@media (max-width: 390px) {
  .title h2 {
    font-size: 24px;
  }
}
