.input-box input {
  height: 40px;
  width: -webkit-fill-available;
  width: -moz-available;
  outline: none;
  border: 1px solid #ccc;
  padding-left: 10px;
  font-size: 16px;
  font-family: inherit;
  display: flex;
  display: -webkit-flex;
  flex: 1 0 0;
  -webkit-flex: 1 0 0;
  -webkit-appearance: none;
  border-radius: 0;
}
.input-box input:focus {
  border-color: #3f78e0;
  box-shadow: 0px 0px 4px #3f78e0;
}
.error-message {
  color: red;
  margin-top: 5px;
  font-size: 14px;
}
.invalid-input {
  border: 1px solid red !important;
}
