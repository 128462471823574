.send-email-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 20px;
  margin-bottom: 50px;
}
.send-email-wrapper {
  width: 563px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-top: 20px;
}
.send-email-container .back-arrow {
  height: 28px;
  width: 28px;
  position: absolute;
  left: -40px;
  top: 6px;
  cursor: pointer;
}
.send-email-container .title {
  max-width: 1330px;
  width: 100%;
  display: flex;
  position: relative;
  gap: 5px;
}
@media (max-width: 563px) {
  .send-email-wrapper {
    width: 100%;
  }
  .back-arrow {
    display: none;
  }
}
