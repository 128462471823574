.user-edit-container {
  font-family: inherit;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.719);
  display: grid;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.user-edit-wrapper {
  background-color: rgb(255, 255, 255);
  width: 450px;
  min-height: 450px;
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  border-radius: 7px;
}
.user-edit-wrapper .title {
  white-space: nowrap;
  margin-bottom: 20px;
  position: relative;
}

.user-edit-wrapper .title::after {
  content: '';
  height: 1px;
  width: 530px;
  background-color: #272e6e;
  position: absolute;
  bottom: 0;
  left: -40px;
}
.user-details {
  display: flex;
  flex-direction: column;
  width: inherit;
  gap: 15px;
  margin-top: 10px;
}
.detail {
  display: grid;
  grid-template-columns: 100px 330px;
  grid-auto-flow: column;
  align-items: center;
  width: 100%;
}
.detail-title {
  font-weight: 700;
}
.detail-value {
  margin-left: 10px;
  padding: 5px;
  width: 100%;
  background-color: whitesmoke;
  overflow-x: auto;
}
.user-edit-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 20px;
}
.dropdown {
  width: 100%;
}
.dropdown .select-box {
  width: 100%;
}
.dropdown .select__option,
.dropdown .invalid-input__option {
  margin-top: -5px;
  min-height: 35px;
}
.user-edit-container .select__menu,
.user-edit-container .select__menu-list {
  max-height: 140px;
}
@media (max-width: 520px) {
  .user-edit-wrapper {
    width: 260px;
  }
  .user-edit-wrapper .title::after {
    width: 340px;
  }
  .detail {
    grid-auto-flow: row;
    grid-template-columns: 96%;
  }
  .detail-value {
    margin-left: 0;
  }
  .user-edit-wrapper .title h2 {
    font-size: 25px;
  }
}
