.account-container {
  font-family: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.account-wrapper {
  margin-top: 20px;
  max-width: 550px;
  width: 100%;
}
.account-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.account-header .btn-primary {
  height: 30px;
  width: 150px;
}
.account-wrapper .account-title {
  position: relative;
}
.account-wrapper .account-title h2 {
  color: #272e6e;
  margin: 0;
  margin-left: -2px;
  font-size: 28px;
}
.account-wrapper .account-title .back-arrow-svg {
  position: absolute;
  cursor: pointer;
  top: 7px;
  left: -40px;
  height: 28px;
  width: 28px;
}
.account-info-container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.edit-profile-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}
@media (max-width: 550px) {
  .account-wrapper .account-title .back-arrow-svg {
    display: none;
  }
  .account-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
}
