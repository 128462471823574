.all-books-container {
  display: flex;
  justify-content: center;
}
.all-books-container .view-books-wrapper {
  max-width: 1330px;
  width: 100%;
  margin: 20px;
  font-family: inherit;
}
.all-books-container .view-books-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 30px;
}
.all-books-container .title {
  display: flex;
  align-items: baseline;
  position: relative;
}
.all-books-container .back-arrow-svg {
  height: 28px;
  width: 28px;
  cursor: pointer;
  position: absolute;
  left: -40px;
  top: 7px;
}
.all-books-container .view-books-header h2 {
  color: #272e6e;
  margin: 0;
  margin-left: -2px;
  font-size: 28px;
}

.all-books-container .filter-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
.all-books-container .all-filters-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-end;
}
.all-books-container .radio-buttons-container {
  display: flex;
  align-items: center;
  gap: 20px;
}
.all-books-container .two-filters-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.all-books-container .asc-filter {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid #ccc;
  height: 32px;
  width: 100px;
  padding-left: 10px;
  align-self: flex-start;
}
.all-books-container .arrow-svg {
  width: 10px;
  height: 10px;
}
.all-books-container .input-box,
.all-books-container .select-box {
  width: 160px;
}
.all-books-container .input-box input {
  height: 30px;
}
.all-books-container .radiobox {
  height: 34px;
}
.all-books-container .select__control {
  height: 34px !important;
  min-height: 10px !important;
}
.all-books-container .select__placeholder,
.all-books-container .select__single-value {
  margin-top: -4px;
}
.all-books-container .select__option {
  font-size: 15px;
}
.all-books-container .books-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 30px;
  max-width: 100%;
}
.loader {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.load-spin {
  width: 30px;
  height: 30px;
}
.all-books-container .filter-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  overflow-x: clip;
  z-index: 1;
  padding-right: 1px;
  padding: 0 1px;
}
.slide-in {
  transition: 0.5s ease-out, max-height 0.5s ease-out;
  transform: translateX(0%);
  max-height: 95px;
}

.slide-out {
  transition: 0.5s ease-out, max-height 0.5s ease-out;
  transform: translateX(94%);
  max-height: 40px;
}
.library .slide-in {
  transition: 0.5s ease-out;
  transform: translateX(0%);
}
.library .slide-out {
  transition: 0.5s ease-out;
  transform: translateX(89.6%);
}
.filter-svg2-container {
  display: none;
}
.filter-svg-container {
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: flex-start;
}

@media (max-width: 1510px) {
  .all-books-container .view-books-wrapper {
    max-width: 1060px;
  }
  .all-books-container .books-container {
    grid-template-columns: repeat(4, 1fr);
  }

  .all-books-container .filter-container {
    justify-content: flex-start;
    gap: 10px;
  }
  .all-books-container .two-filters-container {
    flex-direction: column;
    gap: 10px;
  }
  .library .two-filters-container {
    flex-direction: row;
  }
  .slide-out {
    transition: 0.5s ease-out;
    transform: translateX(90%);
  }
}
@media (max-width: 1200px) {
  .all-books-container .view-books-wrapper {
    max-width: 790px;
  }
  .all-books-container .view-books-header {
    flex-direction: column;
    gap: 20px;
  }
  .all-books-container .books-container {
    grid-template-columns: repeat(3, 1fr);
  }
  .all-books-container .two-filters-container {
    flex-direction: row;
    gap: 10px;
  }
  .all-books-container .filter-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    overflow: hidden;
  }
  .all-books-container .all-filters-container {
    gap: 10px;
  }
  .all-books-container .two-filters-container {
    width: 100%;
    gap: 10px;
  }
  .all-books-container .input-box,
  .all-books-container .select-box {
    width: -webkit-fill-available;
    width: -moz-available;
  }
  .all-books-container .asc-filter {
    width: calc(95.6% / 2);
  }
  .library .asc-filter {
    width: calc(194% / 2);
  }
  .all-books-container .filter-wrapper {
    flex-direction: column;
  }
  .slide-in {
    max-height: 250px;
    transition: max-height 0.3s ease-out;
    overflow: unset !important;
    transform: none;
  }
  .slide-out {
    max-height: 0;
    transition: max-height 0.5s ease-out;
    transform: none;
    overflow: hidden !important;
  }
  .library .slide-in {
    max-height: 80px;
    transition: max-height 0.3s ease-out;
    overflow: unset !important;
    transform: none;
  }
  .library .slide-out {
    max-height: 0px;
    transition: max-height 0.3s ease-out;
    overflow: hidden !important;
    transform: none;
  }
  .filter-svg2-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .filter-svg {
    display: none;
  }
  .filtro {
    display: none;
  }
  .flex-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
@media (max-width: 990px) {
  .all-books-container .view-books-wrapper {
    max-width: 515px;
  }
  .all-books-container .asc-filter {
    width: calc(93.4% / 2);
  }
  .all-books-container .books-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .all-books-container .view-books-header {
    gap: 20px;
  }
  .library .view-books-header {
    flex-direction: column;
  }
  .library .asc-filter {
    width: calc(191% / 2);
  }
}
@media (max-width: 580px) {
  .all-books-container .input-box,
  .all-books-container .select-box {
    width: 100% !important;
  }
  .all-books-container .view-books-wrapper {
    max-width: 242px;
  }
  .all-books-container .filter-container {
    flex-direction: column;
    width: 100%;
  }
  .all-books-container .asc-filter {
    width: -webkit-fill-available;
    width: -moz-available;
  }
  .all-books-container .two-filters-container {
    flex-direction: column;
    width: 100%;
  }
  .all-books-container .radio-buttons-container {
    flex-direction: column;
    width: 100%;
    gap: 10px;
  }
  .all-books-container .books-container {
    grid-template-columns: 1fr;
  }
  .slide-in {
    max-height: 400px;
    transition: max-height 0.3s ease-out;
    overflow: unset !important;
    transform: none;
  }
  .slide-out {
    max-height: 0;
    transition: max-height 0.5s ease-out;
    transform: none;
    overflow: hidden !important;
  }
  .library .slide-in {
    max-height: 170px;
    transition: max-height 0.3s ease-out;
    overflow: unset !important;
    transform: none;
  }
  .library .slide-out {
    max-height: 0px;
    transition: max-height 0.3s ease-out;
    overflow: hidden !important;
    transform: none;
  }
}
@media (max-width: 339px) {
  .all-books-container .back-arrow-svg {
    display: none;
  }
}
