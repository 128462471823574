.forbiden-page {
  margin-top: 50px;
}

.forbiden-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fobiden-title {
  display: flex;
  height: 100px;
  align-items: center;
  gap: 25px;
  font-family: inherit;
}

.fobiden-title img {
  width: 90px;
}

.forbiden-content {
  font-size: 18px;
  font-family: inherit;
}

.forbiden-content span {
  display: flex;
  gap: 20px;
}
