body {
  margin: 0;
  padding: 0;
  font-family: 'Manrope', sans-serif !important;
}

.create-books-main .row-stack {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.create-books-main .row-space-between {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.create-books-main {
  font-family: inherit;
}

.create-books-main .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 20px;
  margin-bottom: 50px;
}
.create-books-main .container {
  width: 563px;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

@media (max-width: 563px) {
  .create-books-main .container {
    width: 100%;
  }
  .create-books-main .row-space-between {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .create-books-main .row-space-between .row-stack {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .create-books-main .row-space-between .input-box,
  .create-books-main .row-space-between .text-area-input-box,
  .create-books-main .row-space-between .select-box,
  .create-books-main .row-space-between .checkbox {
    width: 100% !important;
  }
  .create-books-main .back-arrow {
    display: none;
  }
}
.create-books-main .title {
  max-width: 1330px;
  width: 100%;
  display: flex;
  position: relative;
  gap: 5px;
}
.create-books-main .title h2 {
  margin: 0;
  font-size: 28px;
}
.create-books-main .back-arrow {
  height: 28px;
  width: 28px;
  position: absolute;
  left: -40px;
  top: 6px;
  cursor: pointer;
}
.create-books-main .row-space-between .input-box,
.create-books-main .row-space-between .select-box,
.create-books-main .row-space-between .checkbox {
  width: calc(100% / 2 - 5px);
}

.select-box .invalid-input__control {
  border: 1px solid red !important;
}

.create-books-main .input-box .form-label,
.create-books-main .text-area-input-box .text-area-form-label {
  display: none;
}
