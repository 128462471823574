:root {
  --darkBlue: #0a164c;
  --secondaryColor: #e0e9fa;
  --primaryColor: #3f78e0;
}

.navbar-wrapper {
  font-family: inherit;
  position: relative;
}

.navbar-wrapper .navbar-header {
  height: 30px;
  background: var(--darkBlue);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding: 0 30px;
}

.navbar-wrapper .navbar-header .item {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  color: white;
  font-size: 14px;
  position: relative;
  text-decoration: none;
  cursor: pointer;
}
.navbar-wrapper .navbar-header .item .icon {
  font-size: 14px;
  width: 14px;
  fill: white;
}
.navbar-wrapper .navbar-header .item .icon-lang {
  font-size: 14px;
  width: 14px;
}

.navbar-wrapper .navbar-container {
  background: #ffffff;
  box-shadow: 0px 2px 8px #f0f1f2;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  padding: 0 30px;
  padding-top: 10px !important;
}
.navbar-wrapper .logo-container {
  justify-self: start;
  grid-column: 1;
}
.navbar-wrapper .logo {
  height: 80px;
}
.navbar-wrapper .route-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 45px;
  justify-self: center;
  justify-content: center;
  font-size: 19px;
  font-weight: 600;
  white-space: nowrap;
}
.navbar-wrapper .avatar-container {
  display: flex;
  justify-self: end;
  grid-column: 3;
  position: relative;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.navbar-wrapper .avatar {
  width: 30px;
  height: 30px;
  border-radius: 30px;
}
.navbar-wrapper .avatar-username {
  white-space: nowrap;
}
.navbar-wrapper .user-options {
  background: white;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: -90px;
  right: 0;
  width: 170px;
  box-shadow: 0px 3px 6px -2px rgba(0, 0, 0);
  border-radius: 2px;
  z-index: 2;
}
.navbar-wrapper .language-dropdown {
  background: white;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: -70px;
  right: 0;
  width: 170px;
  box-shadow: 0px 3px 6px -2px rgba(0, 0, 0);
  border-radius: 2px;
  z-index: 2;
  color: black;
}
.navbar-wrapper .option {
  padding: 8px 15px;
  cursor: pointer;
}
.navbar-wrapper .option:hover {
  background-color: rgb(235 235 235 / 45%);
}
.navbar-wrapper .link {
  text-decoration: none;
  color: black;
}
.navbar-wrapper .route-container .link {
  border-top: 3px solid transparent;
}

.navbar-wrapper .route-container .active {
  color: var(--primaryColor);
  border-top: 3px solid #ae0138;
  padding: 10px 0;
}
.navbar-wrapper .management-navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  gap: 45px;
  overflow-x: auto;
  width: -webkit-fill-available;
  width: -moz-available;
  padding: 0 10px;
  white-space: nowrap;
}
.navbar-wrapper .management-navbar a::before {
  display: block;
  content: attr(title);
  font-weight: bold;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}
.navbar-wrapper .management-navbar .manage-active,
.navbar-wrapper .management-mobile-navbar .manage-active {
  font-weight: bold;
}
.mobile-navbar-menu {
  display: none;
}
.menu-svg {
  width: 25px;
  height: 25px;
  fill: #0a164c;
  z-index: 1;
}
.mobile-navbar-container {
  display: none;
  position: fixed;
  right: 0;
  top: 30px;
  width: 250px;
  height: 100vh;
  background: #ffffff;
  box-shadow: 0px 2px 8px #696a7a;
  z-index: 99;
}
.mobile-navbar-wrapper {
  position: relative;
}
.nav-slide-in {
  max-width: 250px;
  transition: max-width 0.3s ease-out;
}
.nav-slide-out {
  max-width: 0;
  transition: max-width 0.3s ease-out;
}
.close-arrow {
  width: 20px;
  height: 20px;
  fill: #0a164c;
  margin: 15px;
  padding-top: 10px;
  z-index: 999;
}
.mobile-nav-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mobile-avatar {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 10px;
  margin: 15px;
  position: relative;
  padding-top: 10px;
  justify-content: flex-end;
  max-width: 175px;
}
.mobile-avatar::after {
  content: '';
  height: 1px;
  width: 250px;
  background-color: #272e6e;
  position: absolute;
  bottom: -10px;
  left: -15px;
}
.mobile-route-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 20px;
  margin-top: 0;
  margin: 15px;
  gap: 15px;
}
.mobile-route-container .link {
  display: flex;
  align-items: center;
  gap: 5px;
}
.mobile-route-container .link .down-arrow {
  width: 15px;
  height: 15px;
}
.mobile-navbar-container .mobile-route-container .active,
.other-options .active {
  color: #3f78e0;
}
.mobile-navbar-container .management-mobile-navbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  margin-left: 35px;
  font-size: 18px;
  margin-bottom: 15px;
}
.mobile-navbar-container .other-options {
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-size: 20px;
  margin: 0 15px;
}
.mobile-navbar-container .other-options .option {
  padding: 0;
  background-color: white;
}
@media (max-width: 1200px) {
  .navbar-wrapper .route-container {
    grid-row: 2;
    grid-column: span 3;
  }
  .navbar-wrapper .route-container {
    gap: 25px;
    width: -webkit-fill-available;
    width: -moz-available;
    overflow-x: auto;
  }
}
@media (max-width: 600px) {
  .navbar-wrapper .avatar-container {
    flex-direction: column-reverse;
    gap: 0;
  }
  .navbar-wrapper {
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 999;
  }
  .global-wrapper {
    margin-top: 95px;
  }
  .navbar-wrapper .logo {
    height: 50px;
  }
  .mobile-navbar-menu {
    display: inherit;
  }
  .route-container,
  .avatar-container,
  .management-navbar {
    display: none !important;
  }
  .navbar-container {
    display: flex !important;
    justify-content: space-between;
  }
  .mobile-navbar-container {
    display: inherit !important;
  }
}
@media (max-width: 500px) {
  .navbar-wrapper .navbar-header {
    padding: 0 12px;
  }
  .navbar-wrapper .navbar-container {
    padding: 0 12px;
  }
  .navbar-wrapper .admin-navbar {
    justify-content: normal;
  }
  .navbar-wrapper .management-navbar {
    justify-content: normal;
  }
}
@media (max-width: 450px) {
  .navbar-wrapper .route-container {
    font-size: 17px;
  }
}

@media (max-width: 330px) {
  .navbar-wrapper .route-container {
    gap: 20px;
  }
  .navbar-wrapper .management-navbar {
    gap: 20px;
  }
  .navbar-wrapper .logo {
    width: 150px;
    height: 40px;
  }
}
